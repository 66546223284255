<template lang="">
    <div class="row">
      <div class="col-9">
          <div class="card dveb-white-card tab-card">
            <b-tabs content-class="mt-3">
              <b-tab content-class="tab-content" title="Satıcı" active><Satici /></b-tab>
              <b-tab title="Konum"><Konum/></b-tab>
              <b-tab title="Kullanıcılar"><Kullanicilar/></b-tab>
              <b-tab title="Dosya"><Dosya/></b-tab>
              <b-tab title="Varlıklar"><Varliklar/></b-tab>
            </b-tabs>
          </div>
        
      </div>
  
      <div class="col-3 ">
        <div class="card dveb-gray-card aside-gray-card ">
          <div class="card-header">ŞekerBank</div>
          <div><p class="text-center mt-5">Banka Şekerbank T.A.Ş</p></div>
          <div class="row mt-5">
            <div class="col-12 font-weight-bold mb-1">
                Grubu:
            </div>
            <div class="col-12 font-weight-bold">
                Kayıt Tarihi:
            </div>
          </div>
          <!-- <div style="width:100%; height:100px" class="bg-danger mt-5 rounded-sm"> -->

          <!-- </div> -->
          <div class="arrowItem">
              <div class="userInfo">
                <p class="id text-center" style="font-size:12px; font-weight:bold;">63e538d5a686a13aea006343, 63e539e6a6ae39a1770e73a9k</p>
                <p class="adres text-center mt-2" style="font-size:12px;">Emniyet Evleri Mah. Eski Büyükdere Cad. No: 1/1A 34415 Kağıthane - İstanbul</p>
            </div>
        </div>
        <button class="w-100 bg-danger text-white p-2 rounded-sm">Sil</button>
            </div>
        </div>
    </div>
  </template>
      </div>
    </div>
  </template>
  <script>
  export default {
      components:{
          Satici: () => import("@/components/manage/bank/details/Satici.vue"),
          Dosya: () => import("@/components/manage/bank/details/Dosya.vue"),
          Kullanicilar: () => import("@/components/manage/bank/details/Kullanicilar.vue"),
          Varliklar: () => import("@/components/manage/bank/details/Varliklar.vue"),
          Konum: () => import("@/components/manage/bank/details/Konum.vue"),
      }
  };
  </script>
  <style lang="scss" >
  .arrowItem{
      display: flex;
      align-items: flex-start;
      justify-content: center;
  }
  .circle-name {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgb(43, 100, 214);
    color: white;
    text-align: center;
    line-height: 30px;
    margin-right: 15px;
  }
  .arrowItem {
    display: flex;
    justify-content: space-between;
    margin: 15px;
  }
  .userInfo{
      width: 100%;
  }
  .userName{
      font-size: 16px;
  }
  .userNum, .userMail{
      font-size: 12px;
      color: #777777;
  }

  </style>
  